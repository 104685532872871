const initState = {
    success: 0,
    dispute: 0,
    invalid: 0,
    duplicate: 0,
};

const cardCountReducer = (state = initState, action) => {
    switch (action.type) {
        case "UPDATE_SUCCESS_COUNT":
            return {
                ...state,
                success: state?.success + 1,
            };
        case "UPDATE_DISPUTE_COUNT":
            return {
                ...state,
                dispute: state?.dispute + 1,
            };
        case "UPDATE_INVALID_COUNT":
            return {
                ...state,
                invalid: state?.invalid + 1,
            };
        case "UPDATE_DUPLICATE_COUNT":
            return {
                ...state,
                duplicate: state?.duplicate + 1,
            };
        case "RESET_CARD_COUNT":
            return {
                ...state,
                success: 0,
                dispute: 0,
                invalid: 0,
                duplicate: 0,
            };
        default:
            return state;
    }
}

export default cardCountReducer;