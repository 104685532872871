const initState = {
    isLoading: false,
    data: [],
    error: null,
  };
  
  const DisputeDetailReducer = (state = initState, action) => {
    switch (action.type) {
      case "SET_DISPUTE_DETAIL_LOADING":
        return {
          ...state,
          isLoading: true,
          error: null,
        };
      case "SET_DISPUTE_DETAIL":
        return {
          ...state,
          isLoading: false,
          data: action.payload,
        };
      case "SET_DISPUTE_DETAIL_ERROR":
        return {
          ...state,
          isLoading: false,
          data: [],
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default DisputeDetailReducer;