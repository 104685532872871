const initState = {
    isLoading: false,
    data: [],
    error: null,
  };
  
  const BatchListReducer = (state = initState, action) => {
    switch (action.type) {
      case "SET_BATCH_LIST_LOADING":
        return {
          ...state,
          isLoading: true,
          error: null,
        };
      case "SET_BATCH_LIST_DATA":
        return {
          ...state,
          isLoading: false,
          data: action.payload,
        };
      case "SET_BATCH_LIST_ERROR":
        return {
          ...state,
          isLoading: false,
          data: [],
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default BatchListReducer;