import { combineReducers } from "redux";
import Auth from "./Auth";
import Theme from "./Theme";
// import { userModuleReducer } from "../../views/app-views/home/state/reducers/index";
// import { userModuleReducer } from "views/app-views/Users/state/reducers";
import SimpleListReducer from "views/app-views/Users/redux/reducers";
import fileUploadReducer from "views/app-views/Users/redux/reducers/fileUpload";
import userDetail from "views/app-views/Users/redux/reducers/userDetail";
import { districtReducer } from "views/app-views/Shared/reducers/district";
import { dsReducer } from "views/app-views/Shared/reducers/ds";
import { userTypeReducer } from "views/app-views/Shared/reducers/userType";
import DisputeListReducer from "views/app-views/Disputes/redux/disputeReducers";
import UserReducer from "views/app-views/home/redux/userReducers";
import DisputeDetailReducer from "views/app-views/Disputes/redux/disputeDetails";
import fileUploadSReducer from "views/app-views/SerialNo/redux/reducer";
import UserListReducer from "views/app-views/PointTransaction/redux/reducer";
import TransListReducer from "views/app-views/PointTransaction/redux/transReducer";
import DisputeProductsReducer from "views/app-views/Disputes/redux/disputeProducts";
import PaymentRequestReducer from "views/app-views/PaymentRequest/redux/reducer";
import BalanceReducer from "views/app-views/PaymentRequest/redux/balanceReducer";
import BankListReducer from "views/app-views/Users/redux/reducers/bankList";
import BranchListReducer from "views/app-views/Users/redux/reducers/branchList";
import BatchListReducer from "views/app-views/Batch/redux/batchReducer";
import BatchDetailReducer from "views/app-views/Batch/redux/batchDetailReducers";
import CustomerReducer from "views/app-views/WebScan/redux/customerReducer";
import packScanReducer from "views/app-views/WebScan/redux/packReducer";
import PackListReducer from "views/app-views/Reports/PackReports/redux/listReducer";
import PackDetailReducer from "views/app-views/Reports/PackReports/redux/detailReducer";
import PackByIdReducer from "views/app-views/Reports/PackReports/redux/detailIdReducer";
import BirthdayReducer from "views/app-views/Reports/BirthdayReports/redux/reducer";
import AdminListReducer from "views/app-views/PointTransaction/redux/adminReducer";
import QuickPaymentReducer from "views/app-views/QuickView/redux/PaymentReducer";
import QuickTranscationReducer from "views/app-views/QuickView/redux/TransactionReducer";
import QuickPackReducer from "views/app-views/QuickView/redux/PackReducer";
import PointBalanceReducer from "views/app-views/QuickView/redux/BalanceReducer";
import AutoResolveReducer from "views/app-views/Disputes/redux/disputeAutoResolve";
import PointsReducer from "views/app-views/Reports/PointReports/redux/reducer";
import PackSummeryReducer from "views/app-views/Reports/PackReports/redux/packSummeryReducer";
import DNSRequestReducer from "views/app-views/Donations/redux/reducer";
import ProductListReducer from "views/app-views/Products/redux/reducer";
import ScannerReducer from "views/app-views/WebScan/redux/webScanReducer";
import cardCountReducer from "views/app-views/WebScan/redux/cardCountReducer";

const reducers = combineReducers({
  theme: Theme,
  auth: Auth,
  user: SimpleListReducer,
  fileUpload: fileUploadReducer,
  userDetails: userDetail,
  district: districtReducer,
  ds: dsReducer,
  role: userTypeReducer,
  dispute: DisputeListReducer,
  userType: UserReducer,
  disputeDetail: DisputeDetailReducer,
  fileUploadS: fileUploadSReducer,
  allUsers: UserListReducer,
  allAdmins: AdminListReducer,
  transaction: TransListReducer,
  products: DisputeProductsReducer,
  payment: PaymentRequestReducer,
  balance: BalanceReducer,
  bank: BankListReducer,
  branch: BranchListReducer,
  batch: BatchListReducer,
  batchDetail: BatchDetailReducer,
  customer: CustomerReducer,
  packScan: packScanReducer,
  pack: PackListReducer,
  packDetail: PackDetailReducer,
  packById: PackByIdReducer,
  birthday: BirthdayReducer,
  quickPayment: QuickPaymentReducer,
  quickTransaction: QuickTranscationReducer,
  quickPack: QuickPackReducer,
  pointBalance: PointBalanceReducer,
  resolver: AutoResolveReducer,
  points: PointsReducer,
  packSummery: PackSummeryReducer,
  dnsRequest: DNSRequestReducer,
  productList: ProductListReducer,
  scannerWeb: ScannerReducer,
  cardCount: cardCountReducer,
});

export default reducers;
