import { Image, Typography } from "antd";
import EmailFailImage from "../../../assets/images/Verification_Fail.png";
import React from 'react';

const { Title } = Typography;

const EmailFailPage = () => {
  return (
    <>
    <div className="align-items-center d-flex justify-content-center mt-4 p-4 flex-column">
      <Image
        src={EmailFailImage}
        alt="email_success_png_image"
        preview={false}
      />
      <Title level={2} type="danger"> Account Verification Failed.</Title>
    </div>
  </>
  )
}

export default EmailFailPage