import { Typography, Form, Input, Button } from "antd";
import React from 'react';

const { Title } = Typography;

const PasswordResetPage = () => {
  const [passwordResetForm] = Form.useForm();

  const tailLayout = {
    wrapperCol: { span: 40 },
  };

  return (
    <>
      <div className="align-items-center d-flex justify-content-center p-5 mt-5 flex-column">
        <Title level={1}>Reset Account Password</Title>
        <div className="w-25 pt-4">
          <Form
            name="passwordResetForm"
            form={passwordResetForm}
            initialValues={{ remember: false }}
          >
            <div className="mb-1">Password</div>
            <Form.Item
              {...tailLayout}
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>
            <div className="mb-1">Confirm Password</div>
            <Form.Item
              {...tailLayout}
              name="passwordConfirm"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },

                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "The passwords that you entered do not match!"
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item {...tailLayout} className="pr-5 pl-5">
              <div className="pr-3 pl-3">
                <Button
                  className="mt-3"
                  type="submit"
                  style={{
                    fontWeight: 500,
                    color: "white",
                    backgroundColor: "#79ceb5",
                  }}
                  htmlType="submit"
                  block
                  // onClick={() => onLoginbinSubmit()}
                >
                  Reset password
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default PasswordResetPage;
