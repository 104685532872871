const initState = {
    isLoading: false,
    data: [],
    page: 1,
    error: null,
  };
  
  const AdminListReducer = (state = initState, action) => {
    switch (action.type) {
      case "SET_LOADING_ADMINS":
        return {
          ...state,
          isLoading: true,
          error: null,
        };
      case "SET_DATA_ADMINS":
        return {
          ...state,
          isLoading: false,
          data: action.payload.data,
        };
      case "SET_ERROR_ADMINS":
        return {
          ...state,
          isLoading: false,
          data: [],
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default AdminListReducer;