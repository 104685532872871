import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Menu, Grid } from "antd";
import IntlMessage from "../util-components/IntlMessage";
import Icon from "../util-components/Icon";
import navigationConfig from "configs/NavigationConfig";
import { connect } from "react-redux";
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from "constants/ThemeConstant";
import utils from "utils";
import { onMobileNavToggle } from "redux/actions/Theme";
import { UserOutlined, FireOutlined,FileSearchOutlined, DollarOutlined, CodeSandboxOutlined, DropboxOutlined, CalendarOutlined, BarcodeOutlined, TeamOutlined, FileTextOutlined, RetweetOutlined, ArrowDownOutlined, QuestionCircleOutlined, InboxOutlined } from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import jwtDecode from "jwt-decode";
import { getSession } from "session/session";


const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const setDefaultOpen = (key) => {
  let keyList = [];
  let keyString = "";
  if (key) {
    const arr = key.split("-");
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};

const SideNavContent = (props) => {
  const {
    sideNavTheme,
    routeInfo,
    hideGroupTitle,
    localization,
    onMobileNavToggle,
  } = props;
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes("lg");
  const closeMobileNav = () => {
    if (isMobile) {
      onMobileNavToggle(false);
    }
  };

  const [tokenData, setTokenData] = useState(null);
  useEffect(() => {
    const token = jwtDecode(getSession());
    setTokenData(token);
  }, []);

  return (
    <Menu
      theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
      mode="inline"
      style={{ height: "100%", borderRight: 0 }}
      defaultSelectedKeys={[routeInfo?.key]}
      defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
      className={hideGroupTitle ? "hide-group-title" : ""}
    >
      {tokenData?.role === "admin" && (
      <><Menu.Item icon={<FileSearchOutlined />} key="quick">
          <span>Quick Search</span>
          <Link
            onClick={() => closeMobileNav()}
            to={`${APP_PREFIX_PATH}/quick`} />
        </Menu.Item><Menu.Item icon={<TeamOutlined />} key="survey">
            <span>Loyalty Users</span>
            <Link
              onClick={() => closeMobileNav()}
              to={`${APP_PREFIX_PATH}/users`} />
          </Menu.Item><Menu.Item icon={<BarcodeOutlined />} key="webscan">
            <span>Web Scan</span>
            <Link
              onClick={() => closeMobileNav()}
              to={`${APP_PREFIX_PATH}/webscan`} />
          </Menu.Item><Menu.Item icon={<QuestionCircleOutlined />} key="dispute">
            <span>Disputes</span>
            <Link
              onClick={() => closeMobileNav()}
              to={`${APP_PREFIX_PATH}/disputes`} />
          </Menu.Item><Menu.Item icon={<DollarOutlined />} key="requests">
            <span>Payment Requests</span>
            <Link
              onClick={() => closeMobileNav()}
              to={`${APP_PREFIX_PATH}/requests`} />
          </Menu.Item><Menu.Item icon={<FireOutlined />} key="donations">
            <span>Donations & Scholarships</span>
            <Link
              onClick={() => closeMobileNav()}
              to={`${APP_PREFIX_PATH}/donations`} />
          </Menu.Item><Menu.Item icon={<DropboxOutlined />} key="batch">
            <span>Payment Batch</span>
            <Link
              onClick={() => closeMobileNav()}
              to={`${APP_PREFIX_PATH}/batch`} />
          </Menu.Item><Menu.SubMenu
            mode="inline"
            key="reports"
            title="Reports"
            icon={<InboxOutlined />}
          >
            <Menu.Item icon={<RetweetOutlined />} key="transaction">
              <span>Point Transaction</span>
              <Link
                onClick={() => closeMobileNav()}
                to={`${APP_PREFIX_PATH}/transaction`} />
            </Menu.Item>
            <Menu.Item icon={<CodeSandboxOutlined />} key="pack">
              <span>Pack Report</span>
              <Link
                onClick={() => closeMobileNav()}
                to={`${APP_PREFIX_PATH}/reports/pack`} />
            </Menu.Item>
            <Menu.Item icon={<DollarOutlined />} key="point">
              <span>Points Report</span>
              <Link
                onClick={() => closeMobileNav()}
                to={`${APP_PREFIX_PATH}/reports/points`} />
            </Menu.Item>
            <Menu.Item icon={<CalendarOutlined />} key="birthday">
              <span>Birthday Report</span>
              <Link
                onClick={() => closeMobileNav()}
                to={`${APP_PREFIX_PATH}/reports/birthday`} />
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.Item icon={<RetweetOutlined />} key="products">
              <span>Product</span>
              <Link
                onClick={() => closeMobileNav()}
                to={`${APP_PREFIX_PATH}/products`} />
            </Menu.Item></>
      )}
      {tokenData?.role === "manager" && (
        <>
        <Menu.Item icon={<FileSearchOutlined />} key="quick">
          <span>Quick Search</span>
          <Link
            onClick={() => closeMobileNav()}
            to={`${APP_PREFIX_PATH}/quick`} />
        </Menu.Item><Menu.Item icon={<TeamOutlined />} key="survey">
            <span>Loyalty Users</span>
            <Link
              onClick={() => closeMobileNav()}
              to={`${APP_PREFIX_PATH}/users`} />
          </Menu.Item><Menu.Item icon={<BarcodeOutlined />} key="webscan">
            <span>Web Scan</span>
            <Link
              onClick={() => closeMobileNav()}
              to={`${APP_PREFIX_PATH}/webscan`} />
          </Menu.Item><Menu.Item icon={<QuestionCircleOutlined />} key="dispute">
            <span>Disputes</span>
            <Link
              onClick={() => closeMobileNav()}
              to={`${APP_PREFIX_PATH}/disputes`} />
          </Menu.Item><Menu.Item icon={<DollarOutlined />} key="requests">
            <span>Payment Requests</span>
            <Link
              onClick={() => closeMobileNav()}
              to={`${APP_PREFIX_PATH}/requests`} />
          </Menu.Item><Menu.Item icon={<FireOutlined />} key="donations">
            <span>Donations & Scholarships</span>
            <Link
              onClick={() => closeMobileNav()}
              to={`${APP_PREFIX_PATH}/donations`} />
          </Menu.Item><Menu.Item icon={<DropboxOutlined />} key="batch">
            <span>Payment Batch</span>
            <Link
              onClick={() => closeMobileNav()}
              to={`${APP_PREFIX_PATH}/batch`} />
          </Menu.Item><Menu.SubMenu
            mode="inline"
            key="reports"
            title="Reports"
            icon={<InboxOutlined />}
          >
            <Menu.Item icon={<RetweetOutlined />} key="transaction">
              <span>Point Transaction</span>
              <Link
                onClick={() => closeMobileNav()}
                to={`${APP_PREFIX_PATH}/transaction`} />
            </Menu.Item>
            <Menu.Item icon={<CodeSandboxOutlined />} key="pack">
              <span>Pack Report</span>
              <Link
                onClick={() => closeMobileNav()}
                to={`${APP_PREFIX_PATH}/reports/pack`} />
            </Menu.Item>
            <Menu.Item icon={<DollarOutlined />} key="point">
              <span>Points Report</span>
              <Link
                onClick={() => closeMobileNav()}
                to={`${APP_PREFIX_PATH}/reports/points`} />
            </Menu.Item>
            <Menu.Item icon={<CalendarOutlined />} key="birthday">
              <span>Birthday Report</span>
              <Link
                onClick={() => closeMobileNav()}
                to={`${APP_PREFIX_PATH}/reports/birthday`} />
            </Menu.Item>
          </Menu.SubMenu>
        <Menu.Item icon={<FireOutlined />} key="donations">
            <span>DNS Requests</span>
            <Link
              onClick={() => closeMobileNav()}
              to={`${APP_PREFIX_PATH}/dns`} />
          </Menu.Item>
          <Menu.Item icon={<RetweetOutlined />} key="products">
              <span>Product</span>
              <Link
                onClick={() => closeMobileNav()}
                to={`${APP_PREFIX_PATH}/products`} />
            </Menu.Item>
        </>
      )}
    </Menu>
  );
};

const TopNavContent = (props) => {
  const { topNavColor, localization } = props;
  return (
    <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
      {navigationConfig.map((menu) =>
        menu.submenu.length > 0 ? (
          <SubMenu
            key={menu.key}
            popupClassName="top-nav-menu"
            title={
              <span>
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                <span>{setLocale(localization, menu.title)}</span>
              </span>
            }
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  key={subMenuFirst.key}
                  popupClassName="top-nav-menu"
                  icon={
                    subMenuFirst.icon ? (
                      <Icon type={subMenuFirst?.icon} />
                    ) : null
                  }
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      <span>
                        {setLocale(localization, subMenuSecond.title)}
                      </span>
                      <Link to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? (
                    <Icon type={subMenuFirst?.icon} />
                  ) : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link to={subMenuFirst.path} />
                </Menu.Item>
              )
            )}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link to={menu.path} /> : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

const MenuContent = (props) => {
  return props.type === NAV_TYPE_SIDE ? (
    <SideNavContent {...props} />
  ) : (
    <TopNavContent {...props} />
  );
};

const mapStateToProps = ({ theme }) => {
  const { sideNavTheme, topNavColor } = theme;
  return { sideNavTheme, topNavColor };
};

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent);
