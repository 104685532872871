import React, { lazy } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from "layouts/auth-layout";
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from "antd";
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from "configs/AppConfig";
import useBodyClass from "hooks/useBodyClass";
import LoginPage from "./app-views/Login/LoginPage";
import { isSession } from "session/session";
import EmailSucessPage from "./app-views/pages/EmailSucessPage";
import EmailFailPage from "./app-views/pages/EmailFailPage";
import PasswordResetPage from "./app-views/pages/PasswordResetPage";
import PrivacyPolicy from "./app-views/pages/PrivacyPolicy";
import TermsCondition from "./app-views/pages/TermsCondition";

export const Views = (props) => {
  const { locale, location, direction } = props;
  const currentAppLocale = AppLocale[locale];
  useBodyClass(`dir-${direction}`);
  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <Switch>
          <Route
            exact
            path="/"
            component={LoginPage}
            render={(props) =>
              isSession() ? (
                <Redirect to={APP_PREFIX_PATH} />
              ) : (
                <Redirect to="/" />
              )
            }
          />
          {/* <Route path={`/email/success`} component={EmailSucessPage} />
          <Route path={`/email/fail`} component={EmailFailPage} />
          <Route path={`/password/reset`} component={PasswordResetPage} /> */}
          <Route path={`/privacy-policy`} component={PrivacyPolicy} />
          <Route path={`/terms-condition`} component={TermsCondition} />
          <Route exact path="/">
            <Redirect to={APP_PREFIX_PATH} />
          </Route>
          <Route path={AUTH_PREFIX_PATH}>
            <AuthLayout direction={direction} />
          </Route>
          <Route path={APP_PREFIX_PATH}>
            <AppLayout direction={direction} location={location} />
          </Route>
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  );
};

const mapStateToProps = ({ theme, auth }) => {
  const { locale, direction } = theme;
  const { token } = auth;
  return { locale, token, direction };
};

export default withRouter(connect(mapStateToProps)(Views));
