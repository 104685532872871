const initState = {
    loading: false,
    message: {},
}

const fileUploadReducer = (state = initState, action) => {
    switch (action.type) {
        case 'SET_LOADING_UPLOAD':
            return {
                ...state,
                loading: true,
                message: {},
            }
        case 'SET_MESSAGE_UPLOAD':
            return {
                ...state,
                loading: false,
                message: action.payload,
            }
        default:
            return state
    }
}

export default fileUploadReducer;