import axios from "axios";
import { AUTH_URL } from "configs/Baseurl";
import jwt from "jwt-decode";

export const ripSession = () => {
  localStorage.removeItem("token");
};

export const getSession = () => {
  return (localStorage.getItem("token"));
};

export function getRefreshToken() {
  if (typeof window !== "undefined") {
    return localStorage.getItem("refresh");
  }
}

//save token to session storage
export function saveUser(data) {
    if (typeof window !== "undefined") {
      window.localStorage.setItem("ac_token", data.access_token);
    }
  }

// export const isSession = () => {
//  if (localStorage.getItem("token")) {
//     const token = jwt(getSession());
//     const now = new Date();
//     if (token.exp < now.getTime() / 1000) {
//       ripSession();
//       return false;
//     }
//     return true;
//   }

//   return false;
// };

export const isSession = () => {
  if (localStorage.getItem("token") === null) {
console.log("token null")
  }
  if (localStorage.getItem("token")) {
    try{
    const token = jwt(getSession());
    
    const now = new Date();
    if (token.exp < now.getTime() / 1000) {
      const token = localStorage.getItem("token");
      const refresh_token = localStorage.getItem("refresh_token");
      const config = {
        headers: {
          "Authorization": `Bearer ${JSON.parse(token)}`,
          "Access-Control-Allow-Origin": '*',
          "Accept": "application/json",
          "Content-Type": "application/json",
        }
      };
      const body = {
        refresh_token: JSON.parse(refresh_token)
      }
      axios.post(`${AUTH_URL}/api/v1/auth/mobile/refresh`, body, config).then(
        // axios.post(`https://loyaltyapi.orel.com/auth/api/v1/auth/mobile/refresh`, body, config).then(
        (res) => {
          // ripSession();
          localStorage.removeItem("token");
          localStorage.setItem("token", JSON.stringify(res.data.access_token));  
          window.location.reload();
          return true;
        }

      ).catch((err) => {
        ripSession();
        window.location.href = "/";
        return false;
      });
      // ripSession();
      // return false;
    }
    return true;
  }catch(err){
    console.log(err)
    ripSession();
    window.location.href = "/";
    return false;
  }
  }

  return false;
};