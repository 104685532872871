const initialState = {
    dsList: [],
    loading: false,
    showMessage: false,
    messageAlert: null,
}

export const dsReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_DS_LIST":
            return {
                ...state,
                loading: true,
            };
        case "GET_DS_SUCCESS":
            return {
                ...state,
                dsList: action.payload,
                loading: false,
            };
        case "GET_DS_LIST_ERROR_SHOW":
            return {
                ...state,
                loading: false,
                showMessage: true,
                messageAlert: action.message,
            };
        case "GET_DS_LIST_ERROR_HIDE":
            return {
                ...state,
                showMessage: false,
                messageAlert: null,
            };
        case "GET_DS_LIST_CLEAR":
            return {
                ds: [],
                loading: false,
                showMessage: false,
                messageAlert: null,
            };
        default:
            return state;
    }
}